<template>
    <section id="presentation">
        <!-- horizontal banner -->
        <div class="horizontal-banner d-flex align-items-center">
            <div class="container">
                <div class="d-flex flex-column justify-content-center align-items-center text-center">
                    <h2 style="color:#fff">Une communauté de professionnels dans l'action</h2>
                </div>
            </div>
        </div>

        <div class="container my-5">
            <h3 class="text-center">La CPTS******* est une communauté de professionnels de santé de libéraux,
            d'hospitaliers et de structures médicaux sociales, rassemblée pour améliorer le parcours
            de santé de ses patients et la fluidité des soins entre l'hôpital et la ville, au plus près des 
            spécificités de notre territoire.
            Notre mission est également d'optimiser l'accès au médecin et aux professionnels tels que 
            kinésithérapeute, chirurgien-dentiste...
            </h3>
        </div>

        <!-- horizontal banner -->
        <div class="horizontal-banner d-flex align-items-center">
            <div class="container">
                <div class="d-flex flex-column justify-content-center align-items-center text-center">
                    <h3>Nos spécificités</h3>
                    <button class="btn btn-primary btn-rounded">Voir plus</button>
                </div>
            </div>
        </div>

        <div class="container">
            <h3 class="text-center mt-5">La CPTS *********** est la plus grande CPTS de la région ***************.</h3>
            <p class="text-muted text-center">(rapport nombre d'usagers couverts/taille du territoire)</p>
            <!-- liste des contenus -->
            <div class="d-flex flex-column justify-content-center align-items-center">
                <h1 class="high">>> <span class="color-primary">220000 usagers</span> </h1>
                <h1 class="high">>> <span style="color:#fc2b5d">1200 professionnels de santé</span></h1>
                <h1 class="high">>> <span style="color:#f9c728">1300 KM2</span> </h1>
                <h1 class="high">>> <span class="color-primary">2 départements couverts : <br> Nord Rhône et plateau de l'Ain</span> </h1>
                <h1 class="high">>> <span style="color:#fc2b5d">118 communes</span> </h1>
            </div>
            <hr>
        </div>

        <div class="container">
            <h1 class="text-center">Notre communauté sur le terrain</h1>
            <img src="@/assets/images/import/cpts/people.png" alt="">
        </div>

        <div class="container mt-5">
            <div class="details">
                <div class="d-flex justify-content-start">
                    <h3 class="background-primary radius-right px-5">Bureau de l'association</h3>
                </div>
                <p class="text-center">Comme toute association loi 1901, le bureau est constitué de plusieurs membres : </p>
                <ul>
                    <li><b class="color-primary"> Président: </b> Monsieur X <span class="text-muted">(Kinésithérapeute libéra)</span></li>
                    <li><b class="color-primary"> Vice présidente:</b> Madame Y <span class="text-muted">(Infirmière libérale)</span></li>
                    <li><b class="color-primary"> président:</b> Monsieur X <span class="text-muted">(Kinésithérapeute libéra)</span></li>
                    <li><b class="color-primary"> Vice présidente:</b> Madame Y <span class="text-muted">(Infirmière libérale)</span></li>
                    <li><b class="color-primary"> président:</b> Monsieur X <span class="text-muted">(Kinésithérapeute libéra)</span></li>
                </ul>
            </div>
            <div class="details">
                <div class="d-flex justify-content-end">
                    <h3 class="background-primary radius-left text-right px-5">Equipe de travail</h3>
                </div>
                <p class="text-center">Six équipes de travail proposent <b class="color-primary">des orientations</b>  qui font suite à un 
               <b class="color-primary">long travail de réfléxion et d'analyse</b>  avec des professionnels de santé, des directeurs, 
                    des chefs de services, …. <br><br>

                    Dans chacun de ces groupes, un <b class="color-primary"> référent</b> est chargé de rapporter l'évolution du travail
                    engagé.
                </p>
            </div>
            <div class="details mt-5">
                <div class="d-flex justify-content-start">
                    <h3 class="background-primary radius-right px-5">Equipe de référents</h3>
                </div>
                <p class="text-center">Il <b class="color-primary">oriente</b> la stratégie médico-sanitaire, impulsée préalablement par le diagnostic de 
                terrain et <b class="color-primary">réaxe</b>  si besoin leur proposition en fonction de l'avancée des groupes de travail.
                Ces professionnels à forte capacité d'investissement dans notre communauté se 
                retrouvent aux différentes fonctions de notre organisation.
                </p>
            </div>
            <div class="details mt-5">
                <div class="d-flex justify-content-end">
                    <h3 class="background-primary radius-left text-right px-5">Equipe permanente</h3>
                </div>
                <p class="text-center">La mission est d'assurer la continuité de service que la CPTS propose par ces différentes 
                actions et d'assurer l'animation et la coordination par la force et le professionnalisme
                de cette équipe.
                </p>
                <ul>
                    <li><b class="color-primary">Monsieur A</b>  : Directeur, cadre de santé kinésithérapeute libéral, coordinateur en soins primaire (EHESP) </li>
                    <li><b class="color-primary">Madame B </b> : Coordinatrice de parcours, Infirmière libérale, I.P.A cardiologie</li>
                    <li><b class="color-primary">Madame C </b> : Secrétariat CPTS</li>
                    <li><b class="color-primary">Madame D </b> : Infirmière de parcours CPTS, infirmière en éducation thérapeutique, musicothérapeute</li>
                    <li><b class="color-primary">Madame E </b> : Parcours de soins non programmés, orthophoniste libérale</li>
                </ul>
            </div>
            <div class="details">
                <div class="d-flex justify-content-start">
                    <h3 class="background-primary radius-right px-5">Référents médicaux</h3>
                </div>
                <p class="text-center">Comme toute association loi 1901, le bureau est constitué de plusieurs membres : </p>
                <ul>
                    <li><b class="color-primary"> Madame M : </b>  Référente soins non programmés, médecin généraliste, M.D.S.</li>
                    <li><b class="color-primary"> Monsieur L : </b>  Référent vaccination COVID, médecin généraliste.</li>
                    <li><b class="color-primary"> Madame P : </b>  Référente P.I.C/I.P.A.,  médecin généraliste.</li>
                    <li><b class="color-primary"> Madame M : </b> Référente P.I.C/Hospitalier, Cardiologue C.H.N.O.</li>
                    <li><b class="color-primary"> Madame M : </b>  Référente soins non programmés, médecin généraliste, M.D.S.</li>
                </ul>
            </div>
        </div>

        <div class="container">
            <h1 class="text-center">Une communauté structurée</h1>
            <img class="w-100" src="@/assets/images/import/cpts/union.png" alt="">
        </div>

    </section>
</template>
<script>
export default {
    name:"presentation"
}
</script>
<style scoped>
/* horizonta-banner */
.horizontal-banner .container{
    z-index: 20;
    position: relative;
}
.horizontal-banner {
    min-height: 40vh;
    background-color:red;
    background: url('../../assets/images/import/cpts/couverture.png') ;
}
.horizontal-banner::after {
    content:' ';
    position:absolute;
    width: 100%;
    min-height: 40vh;
    background: rgba(116, 3, 101, 0.548)
}
.horizontal-banner h3 {
    color: #fff;
}
/* liste  */
ul li{
    list-style: none;
    font-size:1.1rem
}
ul li:before{
    content:'>>' !important
}
</style>